import getTicketsBySlugName from '../api/graphql/queries/getTicketsBySlugName';
import translateObj from './translateObj';

// NOTE tags values are the STATUS_OPTIONS value in lowercase
const tags = {
  vip: {
    title: {
      en: 'VIP',
      es: 'VIP',
      ca: 'VIP',
      pt: 'VIP',
    },
    color: '#F8D628',
  },
  sale: {
    title: {
      en: 'Sale',
      es: 'Oferta',
      ca: 'Oferta',
      pt: 'Promoção',
    },
    color: '#A4DF79',
  },
  soldout: {
    title: {
      en: 'Sold out',
      es: 'Sold out',
      ca: 'Sold out',
      pt: 'Sold out',
    },
    color: '#FF7B6D',
  },
  soldoutwithwaitinglist: {
    title: {
      en: 'Sold out',
      es: 'Sold out',
      ca: 'Sold out',
      pt: 'Sold out',
    },
    color: '#FF7B6D',
  },
  comingsoon: {
    title: {
      en: 'Coming soon',
      es: 'Próximamente',
      ca: 'Properament',
      pt: 'Em breve',
    },
    color: '#E4DFDB',
  },
  lasttickets: {
    title: {
      en: 'Last Tickets',
      es: 'Últimas entradas',
      ca: 'Últimes entrades',
      pt: 'Ultimas entradas',
    },
    color: '#FFAF52',
  },
  onsale: {
    title: {
      en: 'On Sale',
      es: 'A La Venta',
      ca: 'A La Venda',
      pt: 'À venda',
    },
    color: '#7DD6AD',
  },
  waitinglist: {
    title: {
      en: 'Waiting List',
      es: 'Lista de Espera',
      ca: 'Llista de Espera',
      pt: 'Lista de Espera',
    },
    color: '#6CB2EB',
  },
  form: {
    title: {
      en: 'Form',
      es: 'Formulario',
      ca: 'Formulari',
      pt: 'Forma',
    },
    color: '#6CB2EB',
  },
};

const literals = {
  from: {
    en: 'from',
    es: 'desde',
    ca: 'des de',
    pt: 'desde',
  },
  fees: {
    en: 'fees',
    es: 'tasas',
    ca: 'taxes',
    pt: 'taxas',
  },
  buy: {
    en: 'Buy',
    es: 'Comprar',
    ca: 'Comprar',
    pt: 'Comprar',
  },
  sellerModalTitle: {
    en: 'Select your selling platform',
    es: 'Selecciona tu plataforma de venta',
    ca: 'Selecciona la teva plataforma de venda',
    pt: 'Selecione sua plataforma de venda',
  },
  sellerModalDescription: {
    en: 'This event is available on the following platforms. Select which one you want to use and we will automatically redirect you to the purchase page.',
    es: 'Este evento está disponible en las siguientes plataformas. Selecciona cuál quieres utilizar y te redirigiremos automáticamente a la página de compra.',
    ca: 'Aquest esdeveniment estarà disponible en les següents plataformes. Selecciona les que vols utilitzar i et redirigirem automàticament a la pàgina de compra.',
    pt: 'Este evento está disponível nas seguintes plataformas. Selecione qual deseja usar e nós o redirecionaremos automaticamente para a página de compra.',
  },
  buttons: {
    registration: {
      en: 'Register',
      es: 'Registrar',
      ca: 'Registrar',
      pt: 'Registrar',
    },
    waitinglist: {
      en: 'Waiting List',
      es: 'Lista de Espera',
      ca: 'Llista de Espera',
      pt: 'Lista de Espera',
    },
  },
};

const REGISTRATION = 'register';
const WAITING_LIST = 'waitinglist';
const SOLD_OUT_WITH_WAITING_LIST = 'soldoutwithwaitinglist';

const transformTickets = async (tickets, language) => {
  const ticketsSlugNames = [];
  tickets.forEach((ticket) => {
    ticketsSlugNames.push(ticket.ticketSlugName);
  });
  const ticketsInfoRaw = await getTicketsBySlugName(ticketsSlugNames);

  return tickets.map((ticket) => {
    const newTicket = ticketsInfoRaw.find((ticketInfoRaw) => ticketInfoRaw.ticketSlugName === ticket.ticketSlugName);
    if (!newTicket || !newTicket.show) return null;

    const sellers = newTicket.sellers ? newTicket.sellers.filter((a) => a.active).sort((a, b) => (a.priority < b.priority ? -1 : 1)) : [];

    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: newTicket?.currency || 'EUR',
    });

    const ticketTags = newTicket?.tags || [];
    const statusAsTag = newTicket?.status?.toLowerCase(); // NOTE: don't really get why we mix status with tags.
    if (newTicket?.status && !ticketTags.includes(statusAsTag) && tags[statusAsTag]) {
      ticketTags.push(statusAsTag);
    }

    const localeLiterals = Object.keys(literals).reduce((acc, key) => {
      acc[key] = translateObj(literals[key], language);
      return acc;
    }, {});

    if (statusAsTag === REGISTRATION) localeLiterals.buy = literals.buttons.registration[language];
    if ([WAITING_LIST, SOLD_OUT_WITH_WAITING_LIST].includes(statusAsTag)) localeLiterals.buy = literals.buttons.waitinglist[language];

    return {
      currency: '', // TODO remove
      _id: newTicket._id,
      ticketReadableName: translateObj(newTicket.ticketReadableName, language),
      price: newTicket?.price ? formatter.format(newTicket?.price || 0) : '',
      showFees: newTicket?.showFee || false,
      show: newTicket?.show || false,
      enabled: sellers?.length > 0,
      tags: ticketTags.map((tag) => {
        const foundTag = tags[tag];
        return {
          title: translateObj(foundTag.title, [language]),
          color: foundTag.color,
        };
      }),
      description: translateObj(newTicket.description, language),
      subtitle: translateObj(newTicket.subtitle, language),
      sellers,
      literals: localeLiterals,
      ticketSlugName: newTicket.ticketSlugName, // required for filtering
    };
  }).filter((ticket) => ticket);
};

const transformTabs = async (tabs, language) => {
  const rawTickets = [];
  tabs?.forEach((tab) => {
    if (tab?.tickets) {
      rawTickets.push(...tab.tickets);
    }
  });

  const tickets = await transformTickets(rawTickets, language);

  const newTabs = [...tabs];
  newTabs.forEach((tab, index) => {
    if (tab?.tickets) {
      newTabs[index].tickets = tab.tickets.map((ticket) => {
        const newTicket = tickets.find((ticketInfoRaw) => ticketInfoRaw.ticketSlugName === ticket.ticketSlugName);
        return newTicket;
      }).filter((ticket) => ticket);
      newTabs[index].title = translateObj(tab.label, language);
    }
  });
  return newTabs;
};

export {
  transformTabs,
  transformTickets,
};
